import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ; 

export default {
  
  getAdvertisementList(status){ 
    return axios.get(url + '/opay-api/advertisement/get/all/'+status).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  getEligibleAdsList(){ 
    return axios.get(url + '/opay-api/advertisement/get/eligible').then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  createNewAdvertise(params){ 
    return axios.post(url + '/opay-api/advertisement/create',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  getAdvertiseById(id){ 
    return axios.get(url + '/opay-api/advertisement/get/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  updateAdvertise(id,params){ 
    return axios.post(url + '/opay-api/advertisement/update/'+id, params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  downloadAdvertiseImg(key){
    return axios.get(url + '/opay-api/advertisement/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  uploadAdvertiseImg(id,formData){
    return axios.post(url + '/opay-api/advertisement/resource-upload/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  uploadAdvertiseImgPreview(id,formData){
    return axios.post(url + '/opay-api/advertisement/preview-upload/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

};